import { ChangeEventHandler, KeyboardEventHandler, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { selectEditableNameAnnotation, updateEditableNameAnnotation } from '../../../redux/rendererReducer';
import { useCustomSelector } from '../../../redux/store';
import { useRenameSubmit } from './useRenameSubmit';

export const useRename = ({
  annotationIdentifier,
  groupIdentifier,
  pointCloudIdentifier,
  cadObjectIdentifier,
  cadObjectGroupIdentifier,
  cadLayerIdentifier,
  wmsLayerIdentifier,
  orthophotoLayerIdentifier,
  name: propsName,
}: {
  annotationIdentifier?: string;
  groupIdentifier?: string;
  pointCloudIdentifier?: string;
  cadObjectIdentifier?: string;
  cadObjectGroupIdentifier?: string;
  cadLayerIdentifier?: string;
  wmsLayerIdentifier?: string;
  orthophotoLayerIdentifier?: string;
  name: string;
}) => {
  const dispatch = useDispatch();
  const { editableNameAnnotation } = useCustomSelector((state) => state.rendererProvider, ['editableNameAnnotation']);

  const editableNameAnnotationId = editableNameAnnotation?.id;
  const nameEditable =
    editableNameAnnotationId &&
    [
      annotationIdentifier,
      groupIdentifier,
      pointCloudIdentifier,
      cadObjectIdentifier,
      cadObjectGroupIdentifier,
      cadLayerIdentifier,
      wmsLayerIdentifier,
      orthophotoLayerIdentifier,
    ].includes(editableNameAnnotationId);
  const submitRename = useRenameSubmit();

  const onToggleNameEditable = useCallback(() => {
    const id =
      annotationIdentifier || groupIdentifier || pointCloudIdentifier || cadLayerIdentifier || wmsLayerIdentifier || orthophotoLayerIdentifier || '';
    let type: 'annotation' | 'group' | 'pointcloud' | 'cadObject' | 'cadObjectGroup' | 'cadLayer' | 'wmsLayer' | 'orthophotoLayer' =
      'annotation';
    if (groupIdentifier) type = 'group';
    else if (pointCloudIdentifier) type = 'pointcloud';
    else if (cadLayerIdentifier) type = 'cadLayer';
    else if (wmsLayerIdentifier) type = 'wmsLayer';
    else if (orthophotoLayerIdentifier) type = 'orthophotoLayer';

    dispatch(
      selectEditableNameAnnotation({
        id,
        name: propsName,
        type,
      }),
    );
  }, [
    annotationIdentifier,
    dispatch,
    groupIdentifier,
    pointCloudIdentifier,
    cadLayerIdentifier,
    wmsLayerIdentifier,
    orthophotoLayerIdentifier,
    propsName,
  ]);

  const onChangeInputName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => dispatch(updateEditableNameAnnotation({ name: event.target.value })),
    [dispatch],
  );

  const onEnterName: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.key === 'Enter') submitRename();
    },
    [submitRename],
  );

  const nameInputComponent = useMemo(() => {
    return (
      <div className="w-full px-1 py-1">
        <input
          className="flex items-center w-full px-4 text-sm dark:text-white border-gray-300 dark:bg-[#313131] focus:ring-transparent focus:border-neon-green-600 dark:focus:border-neon-green-300 rounded-md"
          type="text"
          onBlur={submitRename}
          autoFocus
          value={editableNameAnnotation?.name || ''}
          onChange={onChangeInputName}
          onKeyDown={onEnterName}
        />
      </div>
    );
  }, [editableNameAnnotation?.name, onChangeInputName, onEnterName, submitRename]);

  return [{ onToggleNameEditable }, { nameInputComponent, nameEditable }];
};
