import { useQuery } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import {
  BanknotesIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ClockIcon,
  BuildingOfficeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { Button } from '../../../components/Button';
import { SelectItem } from '../../../components/selects/SelectItem';
import { UserContext } from '../../../contexts/UserContext';
import { ORGANISATIONS } from '../../../graphql/organisation';
import { T } from '../../../translation/src';
import { OrganisationsQuery, OrganisationsQueryVariables, Permissions } from '../../../types/graphqlTypes';
import { AddOrganisationModal, AddOrganisationModalProps } from '../AddOrganisationModal';
import { Icon } from '../../../components/Icon';

const $SwitchOrganisationItem: React.FC2<{ currentOrg?: boolean }> = ({ currentOrg, children }) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`${
            currentOrg
              ? 'dark:text-white bg-neon-green-300 dark:bg-black'
              : active && 'bg-neon-green-300 dark:bg-black dark:text-white'
          } flex w-full items-center rounded-md px-2 py-1 text-sm justify-between`}
        >
          <div className="font-bold">{children}</div>
          {!currentOrg && <ChevronRightIcon className="w-5 h-5" />}
        </button>
      )}
    </Menu.Item>
  );
};
const SwitchOrganisationItem = memo($SwitchOrganisationItem);

const $OrganisationSelect: React.FC<{ size: 'small' | 'large' }> = ({ size }) => {
  const { organisationId: organisationIdParams } = useParams<{ organisationId: string }>();
  const [organisationId, setOrganisationId] = useState(organisationIdParams);
  const navigate = useNavigate();
  const currentUser = useContext(UserContext);
  const organisationsQuery = useQuery<OrganisationsQuery, OrganisationsQueryVariables>(
    ORGANISATIONS,
    currentUser.isSuperAdmin && organisationIdParams ? { variables: { includeIds: [organisationIdParams] } } : {},
  );
  const {
    setTrue: openAddOrganisationModal,
    setFalse: closeAddOrganisationModal,
    value: organisationModalOpen,
  } = useBoolean();
  const organisations = useMemo(
    () => organisationsQuery.data?.organisations || [],
    [organisationsQuery.data?.organisations],
  );
  const currentOrganisation = organisations.find((o) => o.id === organisationId);

  useEffect(() => {
    if (organisationIdParams) setOrganisationId(organisationIdParams);
    else if (!organisationId) setOrganisationId(organisations[0]?.id || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationIdParams, organisations]);

  const onNavigate = useCallback(
    (path: string) => navigate(`/organisations/${organisationId}${path}`),
    [navigate, organisationId],
  );

  const onSwitchOrganisation = useCallback(
    (organisationId: string) => navigate(`/organisations/${organisationId}/projects`),
    [navigate],
  );

  const onCloseAddOrganisationModal: AddOrganisationModalProps['onClose'] = useCallback(
    (args) => {
      closeAddOrganisationModal();
      if (!args) return;
      const organisationId = args.data?.id;
      if (args.isSuccess && organisationId) return navigate(`/organisations/${organisationId}/users`);
    },
    [closeAddOrganisationModal, navigate],
  );

  const navigateUserAndGuests = useCallback(() => onNavigate(`/users`), [onNavigate]);
  const navigateBilling = useCallback(() => onNavigate(`/billing`), [onNavigate]);
  const navigateBillingHistory = useCallback(() => onNavigate(`/billing/history`), [onNavigate]);
  const navigateCompanyInfo = useCallback(() => onNavigate(`/billing/companyInfo`), [onNavigate]);

  const showAmountUsers =
    currentOrganisation?.myPermissions.includes(Permissions.Users) ||
    currentOrganisation?.myPermissions.includes(Permissions.All) ||
    currentUser.isSuperAdmin;

  const smallElement = size === 'small';

  return (
    <>
      <div className="w-full">
        <Menu as="div" className="relative inline-block w-full z-49 dark:text-white">
          <div>
            <Menu.Button className="inline-flex items-center justify-between w-full px-4 py-2 text-sm font-medium group text-eerie-black dark:text-white hover:bg-neon-green-300 dark:hover:bg-black focus:outline-none h-14">
              <div className="flex flex-col items-start justify-start">
                <div>
                  {smallElement ? <Icon icon={BuildingOfficeIcon} className={'w-5'}></Icon> : currentOrganisation?.name}
                </div>

                {!smallElement && (
                  <div className="text-xs text-gray-400 group-hover:text-gray-500 dark:text-neon-green-300 dark:group-hover:text-neon-green-300">
                    {showAmountUsers && (
                      <>
                        {' '}
                        {currentOrganisation?.usersAmount || 0}{' '}
                        {(currentOrganisation?.usersAmount || 0) > 1 ? <T _str="users" /> : <T _str="user" />}
                      </>
                    )}
                  </div>
                )}
              </div>
              <ChevronDownIcon
                className="w-5 h-5 ml-2 -mr-1 text-dark-green-500 dark:text-neon-green-300 dark:group-hover:text-neon-green-300"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className="absolute left-0 w-64 mt-2 origin-top-right bg-white divide-y divide-[#E6E6E6] rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none,
                                 dark:bg-[#313131]"
            >
              <div className="px-1 py-1">
                {(currentOrganisation?.myPermissions.includes(Permissions.All) ||
                  currentOrganisation?.myPermissions.includes(Permissions.Users) ||
                  currentUser.isSuperAdmin) && (
                  <SelectItem icon={UsersIcon} onClick={navigateUserAndGuests}>
                    <T _str="users and guests" swc />
                  </SelectItem>
                )}
                {(currentOrganisation?.myPermissions.includes(Permissions.All) ||
                  currentOrganisation?.myPermissions.includes(Permissions.Billing) ||
                  currentUser.isSuperAdmin) && (
                  <SelectItem icon={BuildingOfficeIcon} onClick={navigateCompanyInfo}>
                    <T _str="organisation info" swc />
                  </SelectItem>
                )}
                {(currentOrganisation?.myPermissions.includes(Permissions.All) ||
                  currentOrganisation?.myPermissions.includes(Permissions.Billing) ||
                  currentUser.isSuperAdmin) && (
                  <SelectItem icon={BanknotesIcon} onClick={navigateBilling}>
                    <T _str="subscription" swc />
                  </SelectItem>
                )}
                {/* {(currentOrganisation?.myPermissions.includes(Permissions.All) ||
                  currentOrganisation?.myPermissions.includes(Permissions.Billing) ||
                  currentUser.isSuperAdmin) && (
                  <SelectItem icon={ClockIcon} onClick={navigateBillingHistory}>
                    <T _str="billing history" swc />
                  </SelectItem>
                )} */}
                {organisations.length > 1 && (
                  <>
                    <hr className="px-2 my-4 border-t border-gray-400" />
                    <div className="px-3 text-xs text-eerie-black dark:text-white">
                      <T _str="switch organisation" swc />
                    </div>
                    {smallElement && (
                      <div className="px-1 py-px" key={currentOrganisation?.id}>
                        <SwitchOrganisationItem currentOrg={true}>{currentOrganisation?.name}</SwitchOrganisationItem>
                      </div>
                    )}
                    <div className="flex flex-col overflow-auto max-h-48">
                      {organisations
                        .filter((organisation) => organisation.id !== organisationId)
                        .map((organisation) => (
                          <div
                            className="px-1 py-px"
                            key={organisation.id}
                            onClick={() => onSwitchOrganisation(organisation.id)}
                          >
                            <SwitchOrganisationItem>{organisation.name}</SwitchOrganisationItem>
                          </div>
                        ))}
                    </div>
                  </>
                )}

                {currentUser.isSuperAdmin && (
                  <div className="flex items-center justify-center w-full px-2 my-2 mt-4">
                    <Button variant="superAdmin" onClick={openAddOrganisationModal} size="xs" className="w-full">
                      <T _str="add organisation" swc />
                    </Button>
                  </div>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <AddOrganisationModal onClose={onCloseAddOrganisationModal} open={organisationModalOpen} />
    </>
  );
};

export const OrganisationSelect = memo($OrganisationSelect);
