import { XCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { memo, useCallback, useMemo } from 'react';
import { T } from '../../translation/src';

const $CircleSpinner: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={classNames('animate-spin', className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};
export const CircleSpinner = memo($CircleSpinner) as typeof $CircleSpinner;

interface ButtonProps {
  variant: 'primary' | 'secondary' | 'tertiary' | 'superAdmin';
  loading?: boolean;
  loadingTitle?: React.ReactNode;
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  onCancel?: () => void;
}

const classNamesByVariant = {
  primary: 'bg-french-violet-600 text-white hover:bg-black',
  secondary: 'border border-2 border-black hover:bg-black hover:text-white dark:text-white dark:border-white',
  tertiary: 'text-black border border-black hover:bg-black hover:text-white dark:text-white dark:border-white',
  superAdmin: 'text-black bg-error-red-500 hover:bg-black hover:text-white',
  disabled: 'bg-gray-300 text-white cursor-not-allowed',
};

const classNamesByVariantBySize = {
  primary: {
    xl: 'text-lg px-5.5 py-4.5',
    lg: 'text-base px-5 py-4',
    md: 'text-base px-4.5 py-3.5',
    sm: 'text-base px-4 py-3',
    xs: 'text-sm px-3.5 py-2.5',
  },
  secondary: {
    xl: 'text-lg px-4.5 py-3.5 m-1',
    lg: 'text-base px-4 py-3 m-1',
    md: 'text-base px-3.5 py-2.5 m-1',
    sm: 'text-base px-3 py-2 m-1',
    xs: 'text-xs px-2.5 py-1.5 m-1',
  },
  tertiary: {
    xl: 'text-lg px-5.5 py-4.5',
    lg: 'text-base px-5 py-4',
    md: 'text-base px-4.5 py-3.5',
    sm: 'text-sm px-4 py-3',
    xs: 'text-xs px-3.5 py-2.5',
  },
  superAdmin: {
    xl: 'text-lg px-5.5 py-4.5',
    lg: 'text-base px-5 py-4',
    md: 'text-base px-4.5 py-3.5',
    sm: 'text-sm px-4 py-3',
    xs: 'text-xs px-3.5 py-2.5',
  },
};

const $Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps> = ({
  variant,
  loading,
  loadingTitle,
  children,
  onClick: propsOnClick,
  size = 'md',
  onCancel,
  ...props
}) => {
  const className = useMemo(() => {
    let className = 'flex items-center justify-center overflow-hidden font-medium transition-all rounded-xl';
    if (!props.className?.includes('absolute')) className += ' relative';
    // if (props.disabled) className += ' ' + classNamesByVariantByMode.disabled;
    if (loading && !onCancel) className += ' cursor-not-allowed';
    return classNames(className, props.className);
  }, [loading, onCancel, props.className]);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (...args) => {
      if (props.disabled) return;
      if (loading) {
        if (onCancel) return onCancel();
        return;
      }
      return propsOnClick?.(...args);
    },
    [loading, props.disabled, propsOnClick, onCancel],
  );

  return (
    <button type="button" {...props} className={className} onClick={onClick}>
      {variant === 'secondary' && <div className="absolute w-full h-full"></div>}
      <div
        className={classNames(
          'flex w-full h-full rounded-full items-center justify-center z-10',
          classNamesByVariant[props.disabled ? 'disabled' : variant] +
            ' ' +
            classNamesByVariantBySize[variant][size] +
            ' ' +
            props.className,
        )}
      >
        <div
          className={classNames(
            'transition transform absolute top-1/2 left-1/2 -translate-x-1/2 flex items-center group w-full h-full justify-center',
            loading ? '-translate-y-1/2 opacity-100' : '-translate-y-12 opacity-0',
          )}
        >
          <div
            className={classNames('flex w-full h-full items-center justify-center', onCancel && 'group-hover:hidden')}
          >
            <CircleSpinner className="w-5 h-5" />
            {loadingTitle && <span className="ml-2">{loadingTitle}</span>}
          </div>
          <div
            className={classNames('hidden w-full h-full items-center justify-center', onCancel && 'group-hover:flex')}
          >
            <XCircleIcon className="w-5 h-5" />
            <span className="ml-1">
              <T _str="cancel" swc />
            </span>
          </div>
        </div>
        <div
          className={classNames(
            'transition-transform transform flex items-center',
            loading ? 'translate-y-14' : 'translate-y-0 space-x-2',
          )}
        >
          {children}
        </div>
      </div>
    </button>
  );
};

export const Button = React.memo($Button);
