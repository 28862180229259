import { FieldPolicy, FieldReadFunction, InMemoryCache, defaultDataIdFromObject, makeVar } from '@apollo/client';
import { KeySpecifier } from '@apollo/client/cache/inmemory/policies';
// @ts-ignore
import possibleTypes from '../types/possibleTypes.json';

export const newMessageCountByConversationId = makeVar<{ [key: string]: number }>({});

const offsetLimitPagination = (
  keyArgs: KeySpecifier | false = false,
  dataKey = 'data',
): FieldPolicy<any> | FieldReadFunction<any> => {
  return {
    keyArgs,
    merge(existing, incoming, { variables, args }) {
      //@ts-ignore
      const { ignoreMerge = false } = variables;
      if (ignoreMerge) return incoming;
      const offset =
        //@ts-ignore
        ((args?.pagination?.page || variables?.page) - 1) * (args?.pagination?.limit || variables?.limit) || 0;
      const merged = existing ? existing[dataKey].slice(0) : [];
      for (let i = 0; i < incoming[dataKey].length; i++) {
        merged[offset + i] = incoming[dataKey][i];
      }
      return { ...incoming, [dataKey]: merged };
    },
  };
};

const cursorLimitUniquePagination = (
  keyArgs: KeySpecifier | false = false,
): FieldPolicy<any> | FieldReadFunction<any> => {
  return {
    keyArgs,
    merge(existing, incoming, { readField, variables }) {
      //@ts-ignore
      const { ignoreMerge = false } = variables;
      if (ignoreMerge) return incoming;
      const merged = existing ? { ...existing.data } : {};
      // @ts-ignore
      (incoming.data || []).forEach((item) => {
        // @ts-ignore
        merged[readField('id', item)] = item;
      });
      return { ...incoming, data: merged };
    },
    read(existing) {
      return (
        existing && {
          ...existing,
          ...(existing.data && { data: Object.values(existing.data) }),
        }
      );
    },
  };
};

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Project: {
      fields: {
        state: {
          merge: true,
        },
      },
    },
    PointCluster: {
      fields: {
        segments: {
          merge: false,
        },
      },
    },
    CadLayer: {
      fields: {
        visibleState: {
          merge(_, incoming = []) {
            return [...incoming];
          },
        },
      },
    },
  },
  dataIdFromObject: (object) =>
    defaultDataIdFromObject({ ...object, id: object.id || object.identifier, _id: object.id || object.identifier }),
});
