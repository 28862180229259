import { UpdateNameActionValue } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class UpdateNameAction implements IAction<UpdateNameActionValue> {
  public identifier: string;
  _previousName?: string;
  constructor(public value: UpdateNameActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    if (this.value.annotationIdentifier) {
      const annotation = state.annotations.find(
        (annotation) => annotation.identifier === this.value.annotationIdentifier,
      );
      if (!annotation) return;
      this._previousName = annotation.name;
      annotation.name = this.value.name;
    } else if (this.value.groupIdentifier) {
      const group = state.groups.find((group) => group.identifier === this.value.groupIdentifier);
      if (!group) return;
      this._previousName = group.name;
      group.name = this.value.name;
    } else if (this.value.pointCloudIdentifier) {
      const pointCloud = state.pointClouds.find(
        (pointCloud) => pointCloud.identifier === this.value.pointCloudIdentifier,
      );
      if (!pointCloud)
        return state.pointClouds.push({ identifier: this.value.pointCloudIdentifier, name: this.value.name });
      if (!pointCloud) return;
      this._previousName = pointCloud.name;
      pointCloud.name = this.value.name;
    } else if (this.value.cadLayerIdentifier) {
      const cadLayer = state.cadLayers.find((cadLayer) => cadLayer.identifier === this.value.cadLayerIdentifier);
      if (!cadLayer) return;
      this._previousName = cadLayer.name;
      cadLayer.name = this.value.name;
    } else if (this.value.wmsLayerIdentifier) {
      const wmsLayer = state.wmsLayers.find((wmsLayer) => wmsLayer.identifier === this.value.wmsLayerIdentifier);
      if (!wmsLayer) return;
      this._previousName = wmsLayer.name;
      wmsLayer.name = this.value.name;
    } else if (this.value.orthophotoLayerIdentifier) {
      const orthophotoLayer = state.orthophotoLayers.find(
        (orthophotoLayer) => orthophotoLayer.identifier === this.value.orthophotoLayerIdentifier,
      );
      if (!orthophotoLayer) return;
      this._previousName = orthophotoLayer.name;
      orthophotoLayer.name = this.value.name;
    }
  }
  undo(state: PointCloudCommandManagerState) {
    if (this.value.annotationIdentifier) {
      const annotation = state.annotations.find(
        (annotation) => annotation.identifier === this.value.annotationIdentifier,
      );
      if (!annotation) return;
      annotation.name = this._previousName || '';
      this._previousName = undefined;
    } else if (this.value.groupIdentifier) {
      const group = state.groups.find((group) => group.identifier === this.value.groupIdentifier);
      if (!group) return;
      group.name = this._previousName || '';
      this._previousName = undefined;
    } else if (this.value.pointCloudIdentifier) {
      const pointCloud = state.pointClouds?.find(
        (pointCloud) => pointCloud.identifier === this.value.pointCloudIdentifier,
      );
      if (!pointCloud) return;
      pointCloud.name = this._previousName || '';
      this._previousName = undefined;
    } else if (this.value.wmsLayerIdentifier) {
      const wmsLayer = state.wmsLayers.find((wmsLayer) => wmsLayer.identifier === this.value.wmsLayerIdentifier);
      if (!wmsLayer) return;
      wmsLayer.name = this._previousName || '';
      this._previousName = undefined;
    } else if (this.value.orthophotoLayerIdentifier) {
      const orthophotoLayer = state.orthophotoLayers.find(
        (orthophotoLayer) => orthophotoLayer.identifier === this.value.orthophotoLayerIdentifier,
      );
      if (!orthophotoLayer) return;
      orthophotoLayer.name = this._previousName || '';
      this._previousName = undefined;
    }
  }
}
