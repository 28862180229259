import { PointCloudState } from '@pointorama/database';
import * as EventEmitter from 'eventemitter3';
import { AddAreaAction } from './actions/AddAreaAction';
import { AddDistanceAction } from './actions/AddDistanceAction';
import { AddGroupAction } from './actions/AddGroupAction';
import { AddPointAction } from './actions/AddPointAction';
import { DeleteAnnotationAction } from './actions/DeleteAnnotationAction';
import { DeleteAnnotationMultiAction } from './actions/DeleteAnnotationMultiAction';
import { DeleteGroupAction } from './actions/DeleteGroupAction';
import { MoveAnnotationsToGroupAction } from './actions/MoveAnnotationsToGroupAction';
import { MovePointAction } from './actions/MovePointAction';
import { UnGroupAction } from './actions/UnGroupAction';
import { UpdateNameAction } from './actions/UpdateNameAction';
import { UpdatePositionAction } from './actions/UpdatePositionAction';
import { AddCadLayerAction } from './actions/AddCadLayerAction';
import { DeleteCadObjectAction } from './actions/DeleteCadObjectAction';
import { DeleteCadLayerAction } from './actions/DeleteCadLayerAction';
import { DeleteCadObjectGroupAction } from './actions/DeleteCadObjectGroupAction';
import { ConvertCadObjectAction } from './actions/ConvertCadObjectAction';
import { AddBoxAction } from './actions/AddBoxAction';
import { EditBoxAction } from './actions/EditBoxAction';
import { AddOrthophotoLayerAction } from './actions/AddOrthophotoLayerAction';
import { DeleteOrthophotoLayerAction } from './actions/DeleteOrthophotoLayerAction';
import { UpdatePositionOrthophotoLayerAction } from './actions/UpdatePositionOrthophotoLayerAction';
import { AddWmsLayerAction } from './actions/AddWmsLayerAction';
import { DeleteWmsLayerAction } from './actions/DeleteWmsLayerAction';
import { UpdatePositionWmsLayerAction } from './actions/UpdatePositionWmsLayerAction';
import { HideCadObjectAction } from './actions/HideCadObjectAction';

export type Action =
  | AddPointAction
  | AddDistanceAction
  | AddAreaAction
  | AddBoxAction
  | DeleteAnnotationAction
  | DeleteAnnotationMultiAction
  | MovePointAction
  | AddGroupAction
  | DeleteGroupAction
  | MoveAnnotationsToGroupAction
  | UnGroupAction
  | UpdateNameAction
  | UpdatePositionAction
  | UpdatePositionWmsLayerAction
  | UpdatePositionOrthophotoLayerAction
  | AddCadLayerAction
  | AddOrthophotoLayerAction
  | AddWmsLayerAction
  | DeleteCadObjectAction
  | HideCadObjectAction
  | DeleteCadObjectGroupAction
  | DeleteCadLayerAction
  | ConvertCadObjectAction
  | DeleteWmsLayerAction
  | DeleteOrthophotoLayerAction
  | EditBoxAction;

export type PointCloudCommandManagerState = PointCloudState;

export class PointCloudCommandManager extends EventEmitter.EventEmitter {
  private _actionsNormal: Action[] = [];
  private _actionsReverse: Action[] = [];
  private _state: PointCloudCommandManagerState = {
    annotations: [],
    groups: [],
    orderedIdentifiers: [],
    pointClouds: [],
    cadLayers: [],
    wmsLayerOrderedIdentifiers: [],
    wmsLayers: [],
    orthophotoLayers: [],
    orthophotoLayerOrderedIdentifiers: [],
  };

  constructor(state?: PointCloudCommandManagerState) {
    super();
    this._state = state
      ? { ...state }
      : {
          annotations: [],
          groups: [],
          orderedIdentifiers: [],
          pointClouds: [],
          cadLayers: [],
          wmsLayerOrderedIdentifiers: [],
          wmsLayers: [],
          orthophotoLayers: [],
          orthophotoLayerOrderedIdentifiers: [],
        };
  }

  execute(action: Action, { redo }: { redo?: boolean } = {}) {
    action.execute(this._state);
    this._actionsNormal.push(action);
    if (!redo) this._actionsReverse = [];
    this.emit('ACTIONS_CHANGED');
  }

  undo() {
    const action = this._actionsNormal.pop();
    if (!action) return;
    action.undo(this._state);
    if (action) this._actionsReverse.push(action);
    this.emit('ACTIONS_CHANGED');
    return action;
  }

  redo() {
    const action = this._actionsReverse.pop();
    this.emit('ACTIONS_CHANGED');
    return action;
  }

  getState() {
    return this._state;
  }

  setState(state: PointCloudCommandManagerState) {
    this._state = JSON.parse(JSON.stringify(state));
  }

  getActionsNormal() {
    return this._actionsNormal;
  }

  getActionsReverse() {
    return this._actionsReverse;
  }

  buildCurrentState() {
    this._actionsNormal.forEach((action) => action.execute(this._state));
  }
}
