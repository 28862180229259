import { FormikSelect } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { useUpdateProjectSettings } from '../../../hooks/modules/project/useUpdateProjectSettings';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T, useT } from '../../../translation/src';
import { ProjectionSystems } from '../../../utils/constants';
import { Project, ProjectUpdateSettingsMutation } from '../../../types/graphqlTypes';
import { FormikConfig } from 'formik';
import { memo, ReactElement, useCallback, useMemo } from 'react';
import * as yup from 'yup';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        projectionSystem: yup.string().required(translations.isRequired),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  projectionSystem: string;
}

export const isValidProjectionSystem = (
  projectionSystem: string,
): projectionSystem is keyof typeof ProjectionSystems => {
  return Object.keys(ProjectionSystems).includes(projectionSystem);
};

const useProjectionSystemTranslations = () => {
  const belgianLamber72 = useT('Belgian Lambert 72');
  const utmZone = useT('UTM zone');
  return { belgianLamber72, utmZone };
};
export const useProjectionSystemOptions = () => {
  const translations = useProjectionSystemTranslations();
  return [
    {
      label: translations.belgianLamber72,
      value: 'BelgianLamber72',
    },
    {
      label: 'RD/NL',
      value: 'RD/NL',
    },
    ...Array.from(new Array(60)).map((_, i) => ({ label: `${translations.utmZone} ${i + 1}N`, value: `utm${i + 1}` })),
    ...Array.from(new Array(60)).map((_, i) => ({ label: `${translations.utmZone} ${i + 1}S`, value: `utm${i + 1}S` })),
  ];
};

export interface ProjectProjectionSystemModalProps {
  onClose: () => void;
  onSuccess?: (args: { project: ProjectUpdateSettingsMutation['projectUpdateSettings'] }) => void;
  open?: boolean;
  project: Pick<Project, 'id'>;
  title?: ReactElement | null;
}
const $ProjectProjectionSystemModal: React.FC2<ProjectProjectionSystemModalProps> = ({
  onClose,
  open,
  onSuccess,
  title,
}) => {
  const validationSchema = useValidationSchema();
  const [updateProjectSettings, { loading }] = useUpdateProjectSettings();
  const projectionSystemOptions = useProjectionSystemOptions();

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    ({ projectionSystem }) => {
      return updateProjectSettings({
        settings: { projectionSystem },
        onCompleted: ({ projectUpdateSettings }) => {
          onSuccess?.({ project: projectUpdateSettings });
        },
      });
    },
    [updateProjectSettings, onSuccess],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { projectionSystem: '' },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema],
  );

  return (
    <CreateModal
      title={title || <T _str="open street map settings" swc />}
      createButtonTitle={<T _str="apply" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={loading}
      noOverflow
    >
      <FormikSelect name="projectionSystem">
        {projectionSystemOptions.map((option) => (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        ))}
      </FormikSelect>
    </CreateModal>
  );
};

export const ProjectProjectionSystemModal = memo($ProjectProjectionSystemModal);
