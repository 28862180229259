import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import * as uuid from 'uuid';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { FormikCADFileInput } from '../../../components/formik/FormFileInput';
import { T } from '../../../translation/src';
import { useExecuteAction } from '../../../hooks/potree/useExecuteAction';
import { useUpload } from '../../../hooks/useUpload';

interface FormValues {
  files?: FileList;
}

interface AddCADLayerModalProps {
  onClose: () => void;
  open?: boolean;
  projectionSystem: string;
  projectId: string;
}
const $AddCADLayerModal: React.FC2<AddCADLayerModalProps> = ({ onClose, open, projectionSystem, projectId }) => {
  const [loading, setLoading] = useState(false);
  const [executeAction] = useExecuteAction();
  const [, { uploadFiles }] = useUpload();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
        setLoading(false);
      }, 250);
    },
    [onClose],
  );

  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async ({ files }, helpers) => {
      setLoading(true);
      const cadLayerId = uuid.v4();
      const file = files?.[0];
      if (!file) return;
      const [{ name }] = await uploadFiles({ files: [file], projectId });
      executeAction({
        type: 'ADD_CAD_LAYER',
        action: {
          cadLayerId,
          name: 'CAD layer',
          fileName: name,
        },
      });
      onSuccess({ helpers });
    },
    [executeAction, onSuccess, projectId, uploadFiles],
  );

  const formik: FormikConfig<FormValues> = useMemo(() => ({ initialValues: {}, onSubmit }), [onSubmit]);

  return (
    <CreateModal
      title={<T _str="Add a new CAD layer" swc />}
      createButtonTitle={<T _str="upload" swc />}
      formik={formik}
      onCancel={onCancel}
      open={open}
      isSubmitting={loading}
      alwaysCancellable
    >
      <FormikCADFileInput name="files" />
    </CreateModal>
  );
};

export const AddCADLayerModal = memo($AddCADLayerModal);
