import { useCallback, useContext, useEffect, useRef } from 'react';
import { RendererContext } from '../../contexts/RendererContext';
import { useRenameSubmit } from '../modules/renderer/useRenameSubmit';
import { useExecuteAction } from './useExecuteAction';
import { RendererToolType, useRendererTools } from './useRenderer';
import { useCustomSelector } from '../../redux/store';

export const useRendererShortcuts = () => {
  const [_, { changeTool }] = useRendererTools();
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const mouseDown = useRef(false);
  const [executeAction] = useExecuteAction();
  const submitRename = useRenameSubmit();
  const { selectedAnnotations } = useCustomSelector(
    (state) => state.rendererProvider,
    ['selectedAnnotations', 'selectedCadItems'],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        submitRename();
        changeTool({ type: RendererToolType.SELECT });
      }
      if (!viewer) return;
      if (event.key === 'v') {
        document.body.style.cursor = 'move';
        viewer.orbitControls.mode = 'DRAG';
      }
      if (event.key === 'f') {
        document.body.style.cursor = 'crosshair';
        viewer.orbitControls.mode = 'PAN';
      }
      if (event.key === 'Enter') {
        if (viewer.clusterTool.active) {
          viewer.clusterTool.finishPointCluster();
        } else {
          if (viewer.measuringTool.currentTool === 'POINT') return;
          viewer.measuringTool.finishCurrentMeasurement();
        }
      }
    },
    [changeTool, submitRename, viewer],
  );

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (!viewer) return;
      document.body.style.cursor = 'default';
      viewer.orbitControls.mode = null;
      if (
        ['Delete', 'Backspace'].includes(event.key) &&
        event.repeat === false &&
        !['INPUT', 'TEXTAREA'].includes((event.target as HTMLElement).nodeName)
      ) {
        if (selectedAnnotations.length > 0) {
          executeAction({
            action: { identifiers: selectedAnnotations.map((annotation) => annotation.identifier) },
            type: 'DELETE_ANNOTATION_MULTI',
          });
        }
      }
    },
    [executeAction, viewer, selectedAnnotations],
  );

  const onMouseDown = useCallback(() => {
    mouseDown.current = true;
  }, []);

  const onMouseUp = useCallback(() => {
    mouseDown.current = false;
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.addEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [onKeyDown, onKeyUp, onMouseDown, onMouseUp]);
};
