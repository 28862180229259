import { createContext } from 'react';
import { ProjectByIdQuery } from '../types/graphqlTypes';
import { ICadObjectGroup } from '../utils/CADFileParser';

export type CadLayer = {
  cadObjectGroups?: Array<ICadObjectGroup>;
  initialCadObjectGroups?: Array<ICadObjectGroup>;
  status: 'LOADING' | 'LOADED';
  visible?: boolean;
} & ProjectByIdQuery['projectById']['state']['cadLayers'][0];
export type InitialCadLayer = { identifier: string; name: string; status: 'INIT' };

export const isInitialCadLayer = (cadLayer: CadLayer | InitialCadLayer): cadLayer is InitialCadLayer =>
  cadLayer.status === 'INIT';

export const isLoadedCadLayer = (cadLayer: CadLayer | InitialCadLayer | null): cadLayer is CadLayer =>
  !!cadLayer && cadLayer.status === 'LOADED';

export type CadLayersContext = ProjectByIdQuery['projectById']['state']['cadLayers'];

export type CadLayersContextType = {
  cadLayers: Array<CadLayer | InitialCadLayer>;
};

export const CadObjectContext = createContext<CadLayersContextType>({
  cadLayers: [],
});
