import { AddCadLayerActionValue, ICadLayer } from '@pointorama/database';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';

export class AddCadLayerAction implements IAction<AddCadLayerActionValue> {
  public identifier: string;
  constructor(public value: AddCadLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const cadLayer: ICadLayer = {
      identifier: this.value.cadLayerId,
      name: this.value.name,
      type: 'CAD',
      fileName: this.value.fileName,
      visibleState: [],
    };
    state.cadLayers = state.cadLayers ? state.cadLayers.concat(cadLayer) : [cadLayer];
    return;
  }
  undo() {
    return;
  }
}
