import { DeleteCadLayerActionValue, ICadLayer } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

const deleteCadLayer = (
  { cadLayerIdentifier }: { cadLayerIdentifier: string },
  { state }: { state: PointCloudCommandManagerState },
) => {
  const removedCadLayer = state.cadLayers.find((cadLayer) => cadLayer.identifier === cadLayerIdentifier);
  state.cadLayers = state.cadLayers.filter((layer) => layer.identifier !== cadLayerIdentifier);
  return removedCadLayer;
};

const undoDeleteCadLayer = (
  { removedCadLayer }: { removedCadLayer: ICadLayer },
  { state }: { state: PointCloudCommandManagerState },
) => {
  state.cadLayers = [...state.cadLayers, removedCadLayer];
};

export class DeleteCadLayerAction implements IAction<DeleteCadLayerActionValue> {
  _removedCadLayer?: ICadLayer;
  public identifier: string;
  constructor(public value: DeleteCadLayerActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const removedCadLayer = deleteCadLayer({ cadLayerIdentifier: this.value.cadLayerIdentifier }, { state });
    this._removedCadLayer = removedCadLayer;
  }
  undo(state: PointCloudCommandManagerState) {
    if (!this._removedCadLayer) return;
    undoDeleteCadLayer({ removedCadLayer: this._removedCadLayer }, { state });
    this._removedCadLayer = undefined;
  }
}
