import type {
  ConvertCadObjectActionValue,
  PointAnnotation,
  DistanceAnnotation,
  AreaAnnotation,
  Annotation,
} from '@pointorama/database';
export type { ConvertCadObjectActionValue } from '@pointorama/database';
import { IAction } from '../types';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { addOrderedIdentifier } from '../helpers/addOrderedIdentifier';

const convertCadObject = (cadObject: ConvertCadObjectActionValue['cadObjects'][number]): Annotation | null => {
  const commonProperties = {
    ...cadObject,
    identifier: cadObject.annotationIdentifier,
  };
  if (cadObject.type === 'POINT')
    return {
      ...commonProperties,
      type: 'POINT',
      position: cadObject.position,
    } as PointAnnotation;
  if (cadObject.type === 'LINE')
    return {
      ...commonProperties,
      points: cadObject.points.map((p) => ({ position: p, pointCloudId: undefined })),
      type: 'DISTANCE',
    } as DistanceAnnotation;
  if (cadObject.type === 'POLYGON')
    return {
      ...commonProperties,
      points: cadObject.points.map((p) => ({ position: p, pointCloudId: undefined })),
      type: 'AREA',
    } as AreaAnnotation;
  return null;
};

export class ConvertCadObjectAction implements IAction<ConvertCadObjectActionValue> {
  public identifier: string;
  constructor(public value: ConvertCadObjectActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    for (const cadObject of this.value.cadObjects) {
      const annotation = convertCadObject(cadObject);
      if (!annotation) continue;
      state.annotations.push(annotation);
      if (!annotation) continue;
      addOrderedIdentifier(state, {
        groupIdentifier: undefined,
        identifier: cadObject.annotationIdentifier,
        itemIdentifierKey: 'annotations',
        orderedIdentifiersKey: 'orderedIdentifiers',
      });
    }
  }
  undo() {
    return;
  }
}
