import { DeleteCadObjectActionValue, ICadLayer } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class DeleteCadObjectAction implements IAction<DeleteCadObjectActionValue> {
  _previousVisibleState?: ICadLayer['visibleState'];
  public identifier: string;
  constructor(public value: DeleteCadObjectActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const cadLayer = state.cadLayers.find((layer) => layer.identifier === this.value.cadLayerIdentifier);
    if (!cadLayer) return;
    this._previousVisibleState = cadLayer.visibleState;
    cadLayer.visibleState = [
      ...(cadLayer.visibleState || []),
      { index: this.value.index, isGroup: false, subIndex: this.value.subIndex, type: 'DELETE' },
    ];
  }
  undo(state: PointCloudCommandManagerState) {
    const cadLayer = state.cadLayers.find((layer) => layer.identifier === this.value.cadLayerIdentifier);
    if (!cadLayer) return;
    cadLayer.visibleState = this._previousVisibleState;
  }
}
