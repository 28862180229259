import { useCallback, useContext } from 'react';
import pako from 'pako';
import * as base64 from 'byte-base64';
import Worker from '../../../workers/CADParserWorker?worker';
import { EventInput } from '../../../workers/CADParserWorker';
import { CadLayer, InitialCadLayer } from '../../../contexts/CadLayersContext';
import { isNotNullOrUndefined } from '../../../utils/isNullOrEmpty';
import { UserContext } from '../../../contexts/UserContext';
import { keyBy } from 'lodash/fp';

type Point = { x: number; y: number; z: number };

export const compressData = (data: Point[]) => {
  try {
    const jsonString = JSON.stringify(data);
    const compressed = pako.gzip(jsonString);
    const base64String = base64.bytesToBase64(compressed);

    return base64String;
  } catch (error) {
    console.log(error);
    return '';
  }
};

const filterCadObjects = ({
  cadLayer,
  visibleState,
  userId,
}: {
  cadLayer: CadLayer;
  visibleState: CadLayer['visibleState'];
  userId: string;
}) => {
  const stateIndex = visibleState[0];
  if (!stateIndex) return cadLayer;
  if (stateIndex.__typename === 'CadLayerVisibleStateDelete') {
    if (stateIndex.isGroup) {
      const newCadLayer = {
        ...cadLayer,
        cadObjectGroups: (cadLayer.cadObjectGroups || []).filter((_, index) => index !== stateIndex.index),
      };

      if (visibleState.length > 1)
        return filterCadObjects({ cadLayer: newCadLayer, visibleState: visibleState.slice(1), userId });
      return newCadLayer;
    } else if (isNotNullOrUndefined(stateIndex.subIndex)) {
      const newCadLayer = {
        ...cadLayer,
        cadObjectGroups: (cadLayer.cadObjectGroups || []).map((cadObjectGroup, index) => {
          if (index !== stateIndex.index) return cadObjectGroup;
          return {
            ...cadObjectGroup,
            cadObjects: cadObjectGroup.cadObjects.filter((_, index) => index !== stateIndex.subIndex),
          };
        }),
      };

      if (visibleState.length > 1)
        return filterCadObjects({ cadLayer: newCadLayer, visibleState: visibleState.slice(1), userId });
      return newCadLayer;
    }
  } else if (stateIndex.__typename === 'CadLayerVisibleStateHidden') {
    let newCadLayer = cadLayer;
    if (stateIndex.userId === userId) {
      if (stateIndex.isLayer) {
        if (stateIndex.layerIdentifier !== cadLayer.identifier) newCadLayer = cadLayer;
        newCadLayer = {
          ...cadLayer,
          visible: stateIndex.visible,
          cadObjectGroups: (cadLayer.cadObjectGroups || []).map((cadObjectGroup) => {
            return {
              ...cadObjectGroup,
              visible: stateIndex.visible,
              cadObjects: cadObjectGroup.cadObjects.map((cadObject) => {
                return {
                  ...cadObject,
                  visible: stateIndex.visible,
                };
              }),
            };
          }),
        };
      } else {
        const newCadObjectGroups = (cadLayer.cadObjectGroups || []).map((cadObjectGroup, index) => {
          if (index !== stateIndex.index) return cadObjectGroup;
          if (stateIndex.isGroup) {
            return {
              ...cadObjectGroup,
              visible: stateIndex.visible,
              cadObjects: cadObjectGroup.cadObjects.map((cadObject) => {
                return {
                  ...cadObject,
                  visible: stateIndex.visible,
                };
              }),
            };
          }
          const newCadObjects = cadObjectGroup.cadObjects.map((cadObject, index) => {
            if (index !== stateIndex.subIndex) return cadObject;
            return {
              ...cadObject,
              visible: stateIndex.visible,
            };
          });
          return {
            ...cadObjectGroup,
            visible: newCadObjects.some((cadObject) => cadObject.visible),
            cadObjects: newCadObjects,
          };
        });
        newCadLayer = {
          ...cadLayer,
          cadObjectGroups: newCadObjectGroups,
          visible: newCadObjectGroups.some((cadObjectGroup) => cadObjectGroup.visible),
        };
      }
    }

    if (visibleState.length > 1)
      return filterCadObjects({ cadLayer: newCadLayer, visibleState: visibleState.slice(1), userId });
    return newCadLayer;
  }
  return cadLayer;
};

export const useDecompressCadLayers = () => {
  const user = useContext(UserContext);
  const userId = user.id;
  const decompressCadLayers = useCallback(
    async ({
      layers,
      projectionSystemDefinition,
      layersToProcess,
    }: Omit<EventInput['data'], 'layers'> & {
      layersToProcess: EventInput['data']['layers'];
      layers: Array<CadLayer | InitialCadLayer>;
    }) => {
      const worker = new Worker();
      const result = await new Promise<Array<CadLayer>>((resolve) => {
        worker.onmessage = (event: MessageEvent) => {
          resolve(event.data);
        };
        worker.postMessage({ layers: layersToProcess, projectionSystemDefinition });
      });
      worker.terminate();
      const processedLayersById = keyBy('identifier', result);

      return layers.map((layer) => {
        const processedLayer = processedLayersById[layer.identifier];
        const cadLayer = processedLayer || layer;
        const initialCadObjectGroups = processedLayer?.cadObjectGroups || cadLayer.initialCadObjectGroups;
        const newCadLayer = filterCadObjects({
          cadLayer: {
            ...cadLayer,
            cadObjectGroups: processedLayer?.cadObjectGroups || cadLayer.initialCadObjectGroups,
          },
          visibleState: cadLayer.visibleState,
          userId,
        });
        return { ...newCadLayer, initialCadObjectGroups };
      });
    },
    [userId],
  );

  return { decompressCadLayers };
};
