import { useParams } from 'react-router-dom';
import { EarthLoader } from '../../components/loaders/EarthLoader';
import { BillingOptions } from '../../modules/billing/BillingOptions';
import { T } from '../../translation/src';
import { useBillingProductsQuery } from '../../types/graphqlTypes';

export const Billing: React.FC2 = () => {
  const { organisationId = '' } = useParams();
  const { loading: billingProductsLoading } = useBillingProductsQuery({ variables: { organisationId } });

  return (
    <div className="flex flex-col w-full h-full pb-4 overflow-auto px-14 dark:bg-[#313131] dark:text-white">
      <div className="z-20 w-full pt-8 text-xl">
        <div>
          <T _str="subscription" swc />
        </div>
      </div>
      <hr className="my-4 border-t border-gray-300" />
      <div className="flex w-full h-full overflow-auto">
        {billingProductsLoading ? (
          <EarthLoader />
        ) : (
          <div className="flex flex-col w-full space-y-4 overflow-auto">
            <BillingOptions className="flex-shrink-0" />
            {/* <div className={classNames(
              "w-full",
              xsDevice ? "space-y-4" : "flex space-x-4"
            )}>
              <BillingInfo />
              <BillingPaymentDetails />
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};
