import { useMemo } from 'react';
import { Language } from '../types/graphqlTypes';

export const useLanguageOptions = () => {
  const options = useMemo(() => {
    return [
      { value: Language.En, label: 'English' },
      { value: Language.Nl, label: 'Nederlands' },
      { value: Language.De, label: 'Deutsch' },
      { value: Language.Fr, label: 'Français' },
      { value: Language.Ja, label: '日本語' },
    ];
  }, []);
  return options;
};
