import { memo, useCallback, useContext } from 'react';
import * as THREE from 'three';
import Focus from '../../../../assets/icons/focus.svg?react';
import {
  Annotation as TypeAnnotation,
  useProjectUpdateClippingMethodMutation,
  ClipMethods,
  ProjectFragment,
  OrthophotoLayer,
} from '../../../../types/graphqlTypes';
import { RendererContext } from '../../../../contexts/RendererContext';
import classNames from 'classnames';
import { HideSelect, HideSelectorProps } from '../../../../components/HideSelector';
import { useToggleHide } from '../../../../hooks/modules/project/useToggleHide';
import { Icon } from '../../../../components/Icon';
import { Measure } from '../../../../../public/potree/potree/utils/Measure';
import { useAnnotations } from '../../../../hooks/potree/useRenderer';
import { AnnotationContextGroup } from '../../../../contexts/AnnotationContext';
import { useParams } from 'react-router-dom';
import { apolloClient } from '../../../../apollo';
import { PROJECT_FRAGMENT } from '../../../../graphql/project';
import { keyBy } from 'lodash/fp';
import { CadLayer } from '../../../../contexts/CadLayersContext';
import { CadObject, ICadObjectGroup } from '../../../../utils/CADFileParser';

interface AnnotationsIconWrapperProps {
  identifier: string;
  visible?: boolean;
  disabled?: boolean;
  annotation?: Pick<TypeAnnotation, 'identifier' | 'groupIdentifier' | 'name'> & {
    type?: TypeAnnotation['type'];
    visible?: boolean;
  };
  pointCloud?: boolean;
  group?: AnnotationContextGroup;
  onToggleHide?: (event: React.MouseEvent) => void;
  cadObject?: CadObject;
  cadObjectGroup?: ICadObjectGroup;
  cadLayer?: CadLayer;
  orthophoto?: OrthophotoLayer;
}

const $AnnotationsIconsWrapper: React.FC2<AnnotationsIconWrapperProps> = ({
  identifier,
  visible,
  disabled,
  annotation,
  pointCloud,
  group,
  cadObject,
  cadLayer,
  orthophoto,
  cadObjectGroup,
  ...props
}) => {
  const [updateClippingMethod] = useProjectUpdateClippingMethodMutation();
  const { projectId = '' } = useParams();
  const { toggleHide } = useToggleHide();
  const project = apolloClient.readFragment<ProjectFragment>({
    fragment: PROJECT_FRAGMENT,
    id: `Project:${projectId}`,
    fragmentName: 'Project',
  });

  const onToggleHideCluster = useCallback(() => {
    if (!annotation || !project) return;
    if (annotation.type !== 'POINTCLUSTER') return;
    updateClippingMethod({
      variables: { projectId, annotationIdentifiers: [annotation.identifier], clipMethod: ClipMethods.None },
      optimisticResponse: {
        __typename: 'Mutation',
        projectUpdateClippingMethod: {
          id: projectId,
          state: {
            annotations: project.state.annotations.map((someAnnotation) => {
              if (someAnnotation.identifier === annotation.identifier && someAnnotation.__typename === 'PointCluster')
                return {
                  ...someAnnotation,
                  annotationFilter: { ...someAnnotation.annotationFilter, clipMethod: ClipMethods.None },
                };
              return someAnnotation;
            }),
          },
        },
      },
    });
  }, [annotation, projectId, updateClippingMethod, project]);

  const onToggleHide: Required<HideSelectorProps>['onClick'] = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (props.onToggleHide) return props.onToggleHide(event);
      let identifiers;
      if (group) {
        identifiers = group.annotations.map((annotation) => annotation.identifier);
      } else {
        identifiers = [identifier];
      }
      toggleHide({ identifiers, visible: !visible });

      if (annotation?.type === 'POINTCLUSTER') onToggleHideCluster();
    },
    [props, group, toggleHide, visible, annotation?.type, onToggleHideCluster, identifier],
  );

  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;

  const [{ annotations }] = useAnnotations();

  const onFocusAnnotation = useCallback(() => {
    // We choose to select the annotation when using the focus tool.
    if (!viewer) return;

    if (annotation?.type === 'POINTCLUSTER') {
      const pointCloudsById = keyBy('identifier', viewer?.scene.pointclouds);
      const segments = viewer.scene.pointClusters.find((cluster) => cluster.identifier === identifier)?.segments;
      if (!segments) return;
      const boundingBoxCoordinates = segments
        .map((segment) => pointCloudsById[segment.pointcloudId]?.segmentList[segment.segmentId])
        .map((segment) => [segment.boundingBox.p1, segment.boundingBox.p2])
        .flat();
      const boundingBox = new THREE.Box3().setFromPoints(boundingBoxCoordinates);
      const node: { boundingBox?: THREE.Box3 } & THREE.Object3D = new THREE.Object3D();
      node.boundingBox = boundingBox;
      viewer.zoomTo(node, 1, 500);
    } else if (annotation?.type === 'BOX') {
      const box = viewer.scene.volumes.find((volume) => volume.identifier === identifier);
      viewer.zoomTo(box, 1, 500);
    } else if (annotation?.type === 'PLANE') {
      const plane = viewer.scene.planes.find((plane) => plane.identifier === identifier)?.circle;
      if (!plane) return;
      viewer.zoomTo(plane, 5, 500);
    } else if (annotation || cadObject) {
      const identifier = annotation?.identifier || cadObject?.identifier;
      const measurement = viewer.scene.measurements.find((measure) => measure.identifier === identifier);
      if (!measurement) return;

      const node: ({ boundingBox?: THREE.Box3 } & Measure) | undefined = viewer.scene.measurements.find(
        (measure) => measure.identifier === identifier,
      );
      if (!node) return;
      const points = measurement.points.map(
        (p) =>
          new THREE.Vector3(
            p.position.x - node.position.x,
            p.position.y - node.position.y,
            p.position.z - node.position.z,
          ),
      );

      if (measurement.type === 'POINT') {
        // expand is done to correct PointMeasure zoom behavior.
        node.boundingBox = new THREE.Box3().setFromPoints(points).expandByVector(new THREE.Vector3(10.0, 10.0, 0.0));
      } else {
        node.boundingBox = new THREE.Box3().setFromPoints(points);
      }

      viewer.zoomTo(node, 1, 500);
    } else if (cadObjectGroup || cadLayer) {
      const elements =
        cadLayer?.cadObjectGroups?.flatMap((cadObjectGroup) =>
          cadObjectGroup.cadObjects.flatMap((cadObject) => cadObject),
        ) ||
        cadObjectGroup?.cadObjects.flatMap((cadObject) => cadObject) ||
        [];
      const node: { boundingBox?: THREE.Box3 } & THREE.Group = new THREE.Group();
      node.boundingBox = new THREE.Box3();

      elements.forEach((cadObject) => {
        if (cadObject.visible) {
          const anNode = viewer.scene.measurements.find((measure) => measure.identifier === cadObject.identifier);
          if (anNode) {
            anNode.points.forEach((point) => {
              node.boundingBox?.expandByPoint(point.position);
            });
          }
        }
      });
      if (node.boundingBox.isEmpty()) return;
      node.boundingBox.expandByVector(new THREE.Vector3(10.0, 10.0, 0.0));
      viewer.zoomTo(node, 1, 500);
    } else if (pointCloud) {
      const node = viewer.scene.pointclouds.find((node) => node.identifier === identifier);
      if (!node) return;
      viewer.zoomTo(node, 1, 500);
    } else if (orthophoto) {
      const node = viewer.scene.orthophotos.find((node) => node.identifier === identifier)?.mesh;
      if (!node) return;
      viewer.zoomTo(node, 1, 500);
    } else {
      const annotationGroup = annotations.find(
        (annotation) => annotation.identifier === identifier,
      ) as AnnotationContextGroup;
      const node: { boundingBox?: THREE.Box3 } & THREE.Group = new THREE.Group();
      node.boundingBox = new THREE.Box3();

      annotationGroup.annotations.forEach((annotation) => {
        if (annotation.visible) {
          const anNode = viewer.scene.measurements.find((measure) => measure.identifier === annotation.identifier);
          if (anNode) {
            anNode.points.forEach((point) => {
              node.boundingBox?.expandByPoint(point.position);
            });
          }
          const boxNode = viewer.scene.volumes.find((volume) => volume.identifier === annotation.identifier);
          if (boxNode) {
            node.add(boxNode.clone());
            node.boundingBox?.expandByPoint(boxNode.position.clone().add(boxNode.scale.clone().divideScalar(2.0)));
            node.boundingBox?.expandByPoint(boxNode.position.clone().sub(boxNode.scale.clone().divideScalar(2.0)));
          }
          const clusterNode = viewer.scene.pointClusters.find(
            (cluster) => cluster.identifier === annotation.identifier,
          );
          if (clusterNode) {
            for (const segment of clusterNode.segments) {
              const boundingBox = viewer?.scene.pointclouds.find(
                (pointcloud) => pointcloud.identifier === segment.pointcloudId,
              ).segmentList[segment.segmentId].boundingBox;
              node.boundingBox?.expandByPoint(boundingBox.p1);
              node.boundingBox?.expandByPoint(boundingBox.p2);
            }
          }
        }
      });
      if (node.boundingBox.isEmpty()) return;
      node.boundingBox.expandByVector(new THREE.Vector3(10.0, 10.0, 0.0));
      viewer.zoomTo(node, 1, 500);
    }
  }, [viewer, annotation, cadObject, cadObjectGroup, cadLayer, pointCloud, orthophoto, identifier, annotations]);

  return (
    <div className="flex justify-end flex-grow">
      <Icon
        className={classNames(
          'flex items-center justify-center w-6 h-6 stroke-current rounded-sm',
          disabled || !visible
            ? 'cursor-not-allowed text-gray-400 dark:text-[#6f717357]'
            : 'cursor-pointer text-[#6F7173] hover:text-black dark:hover:text-neon-green-300',
        )}
        icon={Focus}
        disabled={disabled || !visible}
        onClick={onFocusAnnotation}
      />
      <HideSelect onClick={onToggleHide} hidden={!visible} />
    </div>
  );
};
export const AnnotationsIconsWrapper = memo($AnnotationsIconsWrapper);
