import { useCallback, useContext } from 'react';
import { useRerender } from '../useRerender';
import { RendererContext } from '../../contexts/RendererContext';
import { useCondFeetToMeter } from '../useFormatNumber';
import { InputProps } from '../../components/inputs/Input';
import { useCustomSelector } from '../../redux/store';
import { debounce } from 'lodash';
import { useExecuteAction } from './useExecuteAction';
import { Vector3 } from 'three';
import { useProjectUpdateClippingToleranceMutation } from '../../types/graphqlTypes';

// projectId only required if you want to use tolerance property
export const usePlaneProperties = (projectId?: string) => {
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const rerender = useRerender();
  const { selectedAnnotations } = useCustomSelector((state) => state.rendererProvider, ['selectedAnnotations']);
  const selectedPlaneAnnotation = selectedAnnotations?.at(0);
  const { condFeetToMeter } = useCondFeetToMeter();
  const [executeAction] = useExecuteAction();
  const [updateClippingTolerance] = useProjectUpdateClippingToleranceMutation();

  const selectedPlane = viewer?.scene.planes.find((plane) => plane.identifier === selectedPlaneAnnotation?.identifier);

  const debounceEditAction = useCallback(
    debounce((newParams) => {
      if (!selectedPlaneAnnotation) return;
      executeAction({
        type: 'EDIT_PLANE',
        action: {
          annotationIdentifier: selectedPlaneAnnotation.identifier,
          normal: { x: newParams.normal.x, y: newParams.normal.y, z: newParams.normal.z },
          position: { x: newParams.position.x, y: newParams.position.y, z: newParams.position.z },
        },
      });
    }, 2000),
    [selectedPlaneAnnotation, executeAction],
  );

  const onEditX: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPlane) return;
      const newValue = condFeetToMeter(Number(event.target.value));
      selectedPlane.position.x = newValue;
      debounceEditAction({ position: selectedPlane.position, normal: selectedPlane.getNormal() });
      rerender();
    },
    [rerender, selectedPlane, condFeetToMeter, debounceEditAction],
  );

  const onEditY: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPlane) return;
      const newValue = condFeetToMeter(Number(event.target.value));
      selectedPlane.position.y = newValue;
      debounceEditAction({ position: selectedPlane.position, normal: selectedPlane.getNormal() });
      rerender();
    },
    [rerender, selectedPlane, condFeetToMeter, debounceEditAction],
  );

  const onEditZ: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPlane) return;
      const newValue = condFeetToMeter(Number(event.target.value));
      selectedPlane.position.z = newValue;
      debounceEditAction({ position: selectedPlane.position, normal: selectedPlane.getNormal() });
      rerender();
    },
    [rerender, selectedPlane, condFeetToMeter, debounceEditAction],
  );

  const onEditInclination: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPlane) return;
      const inclination = Number(event.target.value);
      const normalisedInclination = Math.min(Math.max(inclination, 0), 90);
      selectedPlane.setInclinationDegrees(normalisedInclination);
      debounceEditAction({ position: selectedPlane.position, normal: selectedPlane.getNormal() });
      rerender();
    },
    [rerender, selectedPlane, debounceEditAction],
  );

  const onEditBearing: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPlane) return;
      const bearing = Number(event.target.value);
      const normalisedBearing = Math.min(Math.max(bearing, 0), 360);
      selectedPlane.setBearingDegrees(normalisedBearing);
      debounceEditAction({ position: selectedPlane.position, normal: selectedPlane.getNormal() });
      rerender();
    },
    [rerender, selectedPlane, debounceEditAction],
  );

  const onEditTolerance: Required<InputProps>['onChange'] = useCallback(
    (event) => {
      if (!selectedPlane || !projectId) return;

      const tolerance = condFeetToMeter(Number(event.target.value));
      if (!tolerance) return; // in case invalid value is entered, don't change

      const selectedPlaneIdentifiers =
        viewer?.scene.planes
          ?.filter((plane) => selectedAnnotations.some((annotation) => annotation.identifier === plane.identifier))
          ?.map((plane) => plane.identifier) || [];

      selectedPlane.tolerance = tolerance;
      updateClippingTolerance({
        variables: {
          projectId,
          annotationIdentifiers: selectedPlaneIdentifiers,
          tolerance: tolerance,
        },
      });
      rerender();
    },
    [
      condFeetToMeter,
      projectId,
      rerender,
      selectedPlane,
      updateClippingTolerance,
      selectedAnnotations,
      viewer?.scene.planes,
    ],
  );

  return [
    {
      position: selectedPlane?.position || new Vector3(),
      normal: selectedPlane?.getNormal() || new Vector3(),
      inclination: selectedPlane?.getInclinationDegrees() || 0,
      bearing: selectedPlane?.getBearingDegrees() || 0,
      tolerance: selectedPlane?.tolerance || 0.1,
    },
    {
      onEditX,
      onEditY,
      onEditZ,
      onEditInclination,
      onEditBearing,
      onEditTolerance,
    },
  ] as const;
};
