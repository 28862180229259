import { HideCadObjectActionValue, ICadLayer } from '@pointorama/database';
import { v4 as uuid } from 'uuid';
import { PointCloudCommandManagerState } from '../PointCloudCommandManager';
import { IAction } from '../types';

export class HideCadObjectAction implements IAction<HideCadObjectActionValue> {
  _previousVisibleState?: ICadLayer['visibleState'];
  public identifier: string;
  constructor(public value: HideCadObjectActionValue) {
    this.value = value;
    this.identifier = uuid();
  }
  execute(state: PointCloudCommandManagerState) {
    const cadLayer = state.cadLayers.find((layer) => layer.identifier === this.value.cadLayerIdentifier);
    if (!cadLayer) return;
    this._previousVisibleState = cadLayer.visibleState;
    cadLayer.visibleState = [
      ...(cadLayer.visibleState || []),
      {
        index: this.value.index,
        isLayer: this.value.isLayer,
        isGroup: this.value.isGroup,
        subIndex: this.value.subIndex,
        userId: this.value.userId,
        type: 'HIDDEN',
        visible: this.value.visible,
      },
    ];
  }
  undo(state: PointCloudCommandManagerState) {
    const cadLayer = state.cadLayers.find((layer) => layer.identifier === this.value.cadLayerIdentifier);
    if (!cadLayer) return;
    if (this._previousVisibleState) cadLayer.visibleState = this._previousVisibleState;
  }
}
