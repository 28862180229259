export const POINTORAMA_CONTACT_URL = 'https://www.pointorama.com/contact/';

export const EMPTY_ARRAY = [];

export const ProjectionSystems = {
  BelgianLamber72:
    '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs',
  'RD/NL':
    '+proj=sterea +lat_0=52.1561605555556 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.4171,50.3319,465.5524,1.9342,-1.6677,9.1019,4.0725 +units=m +no_defs +type=crs',
  ...Array.from(new Array(60))
    .map((_, i) => `+proj=utm +zone=${i + 1} +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs`)
    .reduce((acc, cur, i) => ({ ...acc, [`utm${i + 1}`]: cur }), {} as Record<string, string>),
  ...Array.from(new Array(60))
    .map((_, i) => `+proj=utm +zone=${i + 1} +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs`)
    .reduce((acc, cur, i) => ({ ...acc, [`utm${i + 1}S`]: cur }), {} as Record<string, string>),
};
